import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import { detectUserLanguage } from './services/languageService'
import zh from './locales/zh.js'
import en from './locales/en.js'

// Font Awesome imports
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookF, faInstagram, faTiktok, faWeixin } from '@fortawesome/free-brands-svg-icons'

// 添加图标到库
library.add(faFacebookF, faInstagram, faTiktok, faWeixin )
library.add(faFacebookF, faInstagram, faTiktok, faWeixin);
const messages = {
    en,
    zh
  };
// 获取用户语言
const userLanguage = detectUserLanguage();
const locale = ['en', 'zh'].includes(userLanguage) ? userLanguage : 'en';

// 创建 i18n 实例
const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: 'en',
    messages
});

// 确保 i18n 正确注册
const app = createApp(App);
app.use(i18n);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');

console.log("🟢 `i18n` 初始化成功:", i18n);
// console.log("🌍 当前语言:", i18n.global.locale.value);
// console.log("🔍 en 语言包:", JSON.stringify(i18n.global.getLocaleMessage('en'), null, 2));
// console.log("🔍 zh 语言包:", JSON.stringify(i18n.global.getLocaleMessage('zh'), null, 2));

