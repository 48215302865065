<template>
  <section id="gallery" class="gallery">
    <div class="container">
      <div id="slide" :style="slideStyle">
        <div v-for="(item, index) in displayItems" :key="item.id" class="item" :class="{ active: index === 1 }" :style="{ backgroundImage: `url('${item.image}')` }">
          <div class="content">
            <div class="name">{{ $t(`gallery.${item.key}.name`) }}</div>
            <div class="des">{{ $t(`gallery.${item.key}.description`) }}</div>
            <button @click="goToMorePhotos" :title="$t('gallery.morePhotos')" :aria-label="$t('gallery.morePhotos')">
              {{ $t('gallery.morePhotos') }}
            </button> 
            </div>
        </div>
      </div>
      <div class="buttons">
        <button id="prev" @click="prevSlide"><i class="fa-solid fa-angle-left"></i></button>
        <button id="next" @click="nextSlide"><i class="fa-solid fa-angle-right"></i></button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Gallery',
  data() {
    return {
      items: [
        {
          id: 1,
          key: 'casualWedding',
          image: require('@/assets/images/Pareja_1_Catedral.jpg'),
          modalId: 'casualModal'
        },
        {
          id: 2,
          key: 'travel',
          image: require('@/assets/images/falda_roja_1.jpg'),
          modalId: 'travelModal'
        },
        {
          id: 3,
          key: 'family',
          image: require('@/assets/images/familia_1.jpg'),
          modalId: 'familyModal'
        },
        {
          id: 4,
          key: 'wedding',
          image: require('@/assets/images/pareja_2_puente.jpg'),
          modalId: 'weddingModal'
        }
      ],
      currentIndex: 0,
      direction: 0,
      transitioning: false
    }
  },
  computed: {
    displayItems() {
      const itemCount = this.items.length;
      const prev = (this.currentIndex - 1 + itemCount) % itemCount;
      const next = (this.currentIndex + 1) % itemCount;
      return [this.items[prev], this.items[this.currentIndex], this.items[next]];
    },
    slideStyle() {
      return {
        transform: `translateX(${-33.33 - this.direction * 33.33}%)`,
        transition: this.transitioning ? 'transform 0.5s' : 'none'
      };
    }
  },
  methods: {
    prevSlide() {
      if (this.transitioning) return;
      this.direction = -1;
      this.transitioning = true;
      this.triggerAnimation();
      setTimeout(() => this.finishTransition(), 500);
    },
    nextSlide() {
      if (this.transitioning) return;
      this.direction = 1;
      this.transitioning = true;
      this.triggerAnimation();
      setTimeout(() => this.finishTransition(), 500);
    },
    goToMorePhotos() {
      window.location.href = '/Morephotos/index.html';
    },
    finishTransition() {
      this.currentIndex = (this.currentIndex + this.direction + this.items.length) % this.items.length;
      this.direction = 0;
      this.transitioning = false;
    },
    triggerAnimation() {
      // 重置动画
      const activeItem = this.$el.querySelector('.item.active');
      if (activeItem) {
        const elements = activeItem.querySelectorAll('.name, .des, button');
        elements.forEach(el => {
          el.style.animation = 'none';
          el.offsetHeight; // 触发重绘
          el.style.animation = null;
        });
      }
    },
  },
}
</script>