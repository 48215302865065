<template>
  <section id="contact">
    <div class="contact">
      <h1>{{ $t('contact.title') }}</h1>
      <p v-for="(key, index) in ['wechat', 'xiaohongshu', 'email', 'tiktok']" :key="index">
        {{ $t(`contact.socialContacts.${key}`) }}
      </p>
      <p>{{ $t('contact.followMe') }}</p>
      <p>{{ $t('contact.moreInfo') }}</p>
    </div>
    <footer>
      {{ $t('footer.copyright') }}
    </footer>
  </section>
</template>
  
  <script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Contact',
    data() {
      return {
        contacts: [
          '微信: Luluna_34',
          '小红书: 西班牙摄影师Lulu',
          '邮箱: Luluphotosbcn@gmail.com',
          '抖音: 西班牙摄影师Lulu'
        ]
      }
    }
  }
  </script>