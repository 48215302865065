export default {
  home: {
    title: 'Lulu Studio',
    description: 'I am Lulu, a professional photographer based in Barcelona. I specialize in wedding/couple/familypersonal portrait photography. I love capturing those beautiful moments in life with my camera, and I hope my work will bring you joy and wonderful memories',
    bookNow: 'Book Now>>>'
  },
  navigation: {
    home: 'Home',
    gallery: 'Gallery',
    pricing: 'Pricing',
    contact: 'Contact'
  },
  language: {
    switchTo: 'CN'
  },
  gallery: {
    casualWedding: {
      name: 'Casual Wedding Dress',
      description: 'Wearing the most beautiful wedding dress, holding your partner\'s hand, walking through the artistic and romantic Barcelona - this experience is one of a kind'
    },
    travel: {
      name: 'Travel Session',
      description: 'Whether traveling alone, with friends, loved ones, or family, we can capture your best travel moments and create unique memories of your journey'
    },
    family: {
      name: 'Family Memories',
      description: 'Time spent traveling with family is usually the most beautiful and precious memory. Being able to capture your family\'s happiness with my camera brightens my day as well'
    },
    wedding: {
      name: 'Wedding Session',
      description: 'Are you looking for the perfect wedding photographer in Barcelona? Prefer an Asian photographer\'s style? Click to see more samples, we can definitely meet your requirements'
    },
    morePhotos: 'View More'
  },
  pricing: {
    title: 'Price',
    subtitle: 'We also provide travel consulting services. If you need customized car service/airport pickup/tour guides or information, please inform us when booking',
    packages: {
      solo: {
        title: 'Solo Travelling Session',
        details: [
          "2 hours | 9 edits - from €220",
          "4 hours | 12 edits - from €340",
          "8 hours | 20 edits - from €480"
        ]
        },
        couple: {
          title: 'Friends/Couple (2 people)',
          details: [
          '2 hours | 12 edits - from €280',
          '4 hours | 21 edits - from €480',
          '8 hours | 25 edits - from €680'
          ]
        },
        family: {
          title: 'Family Session (3-5 people)',
          details: [
          '2 hours | 15 edits - from €300',
          '4 hours | 20 edits - from €500',
          '8 hours | 30 edits - from €700'
          ]
        },
        wedding: {
          title: 'Wedding Session',
          details: [
          '4 hours | 24 edits - from €550',
          'For Wedding/Commercial/Product',
          'Please contact us for customization',
          ]
        }
      },
    terms: {
      title: 'Booking Process & Payment',
      details: [
        '- 30% deposit required, remaining balance due immediately after session',
        '- We will contact you in advance to confirm shooting style, outfit coordination, and locations, etc.',
        '- Raw photos are not included, edited photos within 10 days after selection',
        '- Prices may vary based on specific requirements, please contact us for inquiry',
        '- Accept payment via Euro cash/Paypal/WeChat/Alipay/Bank transfer'
      ]
    },
    notes: {
      title: 'Important Notes',
      details: [
        '- Deposit is non-refundable if the session is canceled for personal reasons',
        '- Prices exclude addtional fees such as trasportation/entrance, etc. Travel time between locations is included in working hours',
        '- Videos are not included, additional video filming and editing by €50/minute',
        '- We can provide props/accessories/clothing, etc. Please inform in advance for specific requirements',
        '- For other needs such as commercial/product/event shooting, please contact us'
      ]
    }
  },
  contact: {
    title: 'Get in Touch',
    followMe: 'Follow us on social medias',
    moreInfo: 'Get more Barcelona photography&film recommendations>>>',
    socialContacts: {
      wechat: 'WeChat: Luluna_34',
      xiaohongshu: 'Rednote: Lulu Photos Barcelona',
      email: 'Email: Luluphotosbcn@gmail.com',
      tiktok: 'TikTok: Lulu Photos Barcelona'
    }
  },
  morePhotos: {
    typingText: "I’m Lulu, a photographer based in Barcelona. Explore my work and discover the best photo spots in the city.From solo portraits to couples, families, weddings, or commercial shoots — I’ve got your photography needs covered.",
    navigation: {
      home: 'Home',
      gallery: 'Gallery',
      pricing: 'Pricing',
      contact: 'Contact'
    },
    studioName: 'Lulu Studio'
  },
  footer: {
    copyright: '© 2025 Lulu Studio. All rights reserved.'
  }
};