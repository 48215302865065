<template>
  <div id="app">
    <nav>
      <ul>
        <li class="icon"><img src="@/assets/images/luluphotos_1.png"></li>
        <li><a href="/home" @click.prevent="goToSection('home')">{{ $t('navigation.home') }}</a></li>
        <li><a href="/gallery" @click.prevent="goToSection('gallery')">{{ $t('navigation.gallery') }}</a></li>
        <li><a href="/pricing" @click.prevent="goToSection('pricing')">{{ $t('navigation.pricing') }}</a></li>
        <li><a href="/contact" @click.prevent="goToSection('contact')">{{ $t('navigation.contact') }}</a></li>
        <!-- 语言切换器 -->
        <li class="lang-switch">
      <div class="lang-btn" @click="toggleLangMenu">
        <span class="material-symbols-outlined">translate</span>
      </div>
      <div class="lang-dropdown" v-show="showLangMenu">
        <a @click="switchLanguage(currentLang === 'EN' ? 'zh' : 'en')">
          {{ currentLang === 'EN' ? '中文' : 'EN' }}
        </a>
      </div>
    </li>
      </ul>
    </nav>

    <Home />
    <Gallery />
    <Pricing />
    <Contact />

    <div class="_social-links">
    <ul class="_links-list">
      <li class="_social-link">
        <a @click.prevent="showWeChatQR" href="#">
          <font-awesome-icon :icon="['fab', 'weixin']" />
        </a>
      </li>
      <li class="_social-link">
        <a href="https://www.instagram.com/lulu.photobcn?igsh=MTVqOXI5cGUzcWdw&utm_source=qr" target="_blank">
          <font-awesome-icon :icon="['fab', 'instagram']" />
        </a>
      </li>
      <li class="_social-link">
        <a href="https://www.tiktok.com/@lulu.photobcn" target="_blank">
          <font-awesome-icon :icon="['fab', 'tiktok']" />
        </a>
      </li>
      <li class="_social-link">
        <a href="https://www.xiaohongshu.com/user/profile/613c810c0000000002024e47" target="_blank">
          <img :src="require('@/assets/images/xiaohongshu.png')" class="xiaohongshu-icon" />
        </a>
      </li>
    </ul>
    </div>

    <div v-if="showQRModal" class="qr-modal" @click="closeQRModal">
      <div class="qr-content" @click.stop>
        <img src="@/assets/images/wechat-qr.jpg" alt="WeChat QR Code">
        <button class="close-btn" @click="closeQRModal">&times;</button>
      </div>
    </div>
  </div>
  
</template>

<script>
import Home from './components/Home.vue'
import Gallery from './components/Gallery.vue'
import Pricing from './components/Pricing.vue'
import Contact from './components/Contact.vue'

export default {
  name: 'App',
  components: {
    Home,
    Gallery,
    Pricing,
    Contact
  },
  data() {
    return {
      showQRModal: false,
      showLangMenu: false
    }
  },
  computed: {
    currentLang() {
      return this.$i18n.locale === 'zh' ? 'CN' : 'EN'
    }
  },
  methods: {
    showWeChatQR() {
      this.showQRModal = true
    },
    closeQRModal() {
      this.showQRModal = false
    },
    toggleLangMenu() {
      this.showLangMenu = !this.showLangMenu
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('user-language', lang)
      this.showLangMenu = false
    },
    goToSection(id) {
      history.pushState({}, '', '/' + id);
      const el = document.getElementById(id);
      if (el) el.scrollIntoView({ behavior: 'smooth' });
    }
  },
  mounted() {
    const path = window.location.pathname.replace('/', '')
    const tryScrollToSection = () => {
      const el = document.getElementById(path)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      } else {
        setTimeout(tryScrollToSection, 100)
      }
    }
    setTimeout(tryScrollToSection, 200)
  }
}
</script>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 10,
    'GRAD' 0,
    'opsz' 24;
  color: white;
}

.lang-btn {
  cursor: pointer;
  padding-top: 0.3rem;
  margin-right: -50rem;
}

.lang-btn svg {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
}

.lang-dropdown {
  position: relative;
  margin-top: 4rem;
  margin-right: -1.3rem;
}

.lang-dropdown a {
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 100;
  cursor: pointer;
  display: block;
}

/* 移动端适配 */
@media screen and (max-width: 430px) and (max-height: 932px) and (-webkit-device-pixel-ratio: 3)  {
  .lang-btn {
    padding-top: 0.5rem;
  }
  
  .lang-btn svg {
    font-size: 1rem;
  }
  .lang-dropdown {
  position: absolute;
  margin-top: 4rem;
  right: 0.8rem;
  }
}
</style>

<style src="@/assets/lulu.css"></style>