<template>
  <section id="home">
    <div v-for="(image, index) in images" :key="index" class="background" :class="{ active: index === currentIndex }" :style="{ backgroundImage: `url('${image}')` }"></div>
    <div class="header">
      {{ $t('home.title') }}
    </div>
    
    <div class="subheader">
      {{ $t('home.description') }}
    </div>
    
    <a id="btn" href="/contact" @click.prevent="goToSection('contact')">{{ $t('navigation.contact') }}</a>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data() {
    return {
      images: [
        require('@/assets/images/ciutadella_1.jpg'),
        require('@/assets/images/199861713015935.jpg'),
        require('@/assets/images/198381712845550.jpg'),
        require('@/assets/images/falda_roja.jpg'),
        require('@/assets/images/tibidabo_1.jpg')
      ],
      currentIndex: 0
    }
  },
  mounted() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }, 5000);
  }
}
</script>