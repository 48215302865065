const LANGUAGE_KEY = 'user-language';

export const detectUserLanguage = () => {
  // 首先检查 localStorage 是否已有语言设置
  const savedLanguage = localStorage.getItem(LANGUAGE_KEY);
  if (savedLanguage) {
    return savedLanguage;
  }

  // 获取浏览器语言设置
  const browserLang = navigator.language || navigator.userLanguage;
  
  // 如果浏览器语言以 'zh' 开头（包括 zh-CN, zh-TW 等），返回中文，否则返回英文
  const language = browserLang.toLowerCase().startsWith('zh') ? 'zh' : 'en';
  
  // 保存语言设置到 localStorage
  localStorage.setItem(LANGUAGE_KEY, language);
  return language;
};
