<template>
  <section id="pricing">
    <header>
      <h1>{{ $t('pricing.title') }}</h1>
      <p>{{ $t('pricing.subtitle') }}</p>
    </header>
    <main>
      <div class="section price">
        <div class="price-card" v-for="(type, index) in packageTypes" :key="index">
          <h2>{{ $t(`pricing.packages.${type}.title`) }}</h2>
          <ul>
            <li v-for="(detail, detailIndex) in $tm(`pricing.packages.${type}.details`)" :key="detailIndex">
              {{ detail }}
            </li>
          </ul>
        </div>
      </div>
      <div class="terms">
        <h2>{{ $t('pricing.terms.title') }}</h2>
        <p v-for="(term, index) in $tm('pricing.terms.details')" :key="index">{{ term }}</p>
      </div>
      <div class="notes">
        <h2>{{ $t('pricing.notes.title') }}</h2>
        <p v-for="(note, index) in $tm('pricing.notes.details')" :key="index">{{ note }}</p>
      </div>
    </main>
  </section>
</template>


<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Pricing',
  data() {
    return {
      packageTypes: ['solo', 'couple', 'family', 'wedding'],
      maxDetailsLength: 0
    };
  },
  methods: {
    updateMaxDetailsLength() {
      if (!this.$i18n || !this.$i18n.global) {
        console.error("❌ i18n 未初始化:", this.$i18n);
        return;
      }

      const locale = this.$i18n.global.locale.value; // ✅ 修复：取 value
      const messages = this.$i18n.global.getLocaleMessage(locale);

      if (!messages.pricing || !messages.pricing.packages) {
        console.error("❌ pricing 为空:", messages);
        return;
      }

      const packages = messages.pricing.packages;
      this.maxDetailsLength = Math.max(
        ...this.packageTypes.map(type => packages[type]?.details?.length || 0)
      );
    },
    getPackageDetails(type) {
      console.log("🔍 获取 package 数据:", type);

      if (!this.$i18n || !this.$i18n.global) {
        console.error("❌ i18n 未正确加载！");
        return [];
      }

      const locale = this.$i18n.global.locale.value || 'zh'; // ✅ 修复：加 .value
      console.log("🌍 当前 locale:", locale);

      const messages = this.$i18n.global.getLocaleMessage(locale);
      console.log("🟢 获取到的 messages:", messages);

      const details = messages?.pricing?.packages?.[type]?.details;

      if (!Array.isArray(details)) {
        console.error(`❌ ${type} 的 details 不是数组:`, details);
        return [];
      }

      return details;
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.updateMaxDetailsLength();
      }
    }
  },
  mounted() {
    console.log("🛠 检查 terms.details:", JSON.stringify(this.$t('pricing.terms.details')));
  }
};
</script>