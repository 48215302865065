export default {
  home: {
    title: 'Lulu Studio',
    description: '我是常驻巴塞罗那的独立摄影师Lulu，专注于为情侣、家庭、个人拍摄旅行照片。我喜欢用镜头记录旅行的美好瞬间，希望能创造独属于你的巴塞罗那回忆。',
    bookNow: '即刻预定>>>'
  },
  navigation: {
    home: '首页',
    gallery: '相册',
    pricing: '价格',
    contact: '预订'
  },
  language: {
    switchTo: 'EN'
  },
  gallery: {
    casualWedding: {
      name: '轻婚纱摄影',
      description: '穿着最美丽的婚纱，在最美好的时光里，牵着伴侣的手，穿行在充满艺术和浪漫气息的巴塞罗那，这样的体验仅此一份'
    },
    travel: {
      name: '旅行拍摄',
      description: '不论是独自出游，或是与闺蜜，爱人，朋友结伴同行，我们都能为您拍摄最佳的旅行照片，为您留下独一无二的旅行回忆'
    },
    family: {
      name: '家庭回忆',
      description: '与家人一起出游的时光通常是最美好也是最难得的回忆，能够举起相机见证您家庭的幸福，也能点亮我的每一天'
    },
    wedding: {
      name: '婚礼跟拍',
      description: '您是否在巴塞罗那寻找最合适的婚礼摄影师？更偏好于亚洲摄影师的风格？点击查看更多样片，我们定能满足您的要求'
    },
    morePhotos: '查看更多样片'
  },
  pricing: {
    title: '价格表',
    subtitle: '我们同时提供各类旅游咨询服务，若您需要定制包车/接送机/游玩攻略/等服务或资讯，请在预订时告知',
    packages: {
      solo: {
        title: '单人旅拍',
        details: [
          '2小时 精修9张 - 220€起',
          '4小时 精修12张 - 340€起',
          '8小时 精修20张 - 480€起'
        ]
      },
      couple: {
        title: '闺蜜/情侣旅拍(2人)',
        details: [
          '2小时 精修12张 - 280€起',
          '4小时 精修21张 - 480€起',
          '8小时 精修25张 - 680€起'
        ]
      },
      family: {
        title: '家庭旅拍(3-5人)',
        details: [
          "2小时 精修15张 - 300€起",
          "4小时 精修20张 - 500€起",
          "8小时 精修30张 - 700€起"
        ]
      },
      wedding: {
        title: '婚礼拍摄',
        details: [
          '4小时 精修24张 - 550€起',
          '婚礼/商业/产品/写真等',
          '定制服务请联系客服咨询价格',
        ]
      }
    },
    terms: {
      title: '拍摄流程与付款',
      details: [
        '- 支付30%定金定档，确定拍摄时间，拍摄结束后需立即支付尾款',
        '- 摄影师会提前与您联系，确定拍摄风格，服装搭配，拍摄地点等',
        '- 拍摄完成后24小时内给底片，客户选择照片后10天内返精修图',
        '- 价格可能根据您的具体需求有所变化，请联系客服咨询',
        '- 支持欧元现金/Paypal/微信/支付宝/银行转账等支付方式'
      ]
    },
    notes: {
      title: '注意事项',
      details: [
        '- 定金为总价30%，需提前支付，若因甲方原因取消拍摄，定金不予退还',
        '- 拍摄价格中不包含交通/餐食/门票/妆造等额外费用，付费景点需要承担摄影师的门票费用，景点与景点之间的通勤计入到拍摄时间内',
        '- 拍摄价格不包含视频，额外的视频拍摄与剪辑价格为每分钟/50€',
        '- 我方可提供部分现有道具，配饰，服装等，如有特殊需求请提前告知',
        '- 若您需要其他的拍摄需求，如商业/产品/活动等，请联系客服咨询'
      ]
    }
  },
  contact: {
    title: '预订',
    followMe: '在社交媒体上关注我',
    moreInfo: '获取更多巴塞罗那旅拍推荐>>>',
    socialContacts: {
      wechat: '微信: Luluna_34',
      xiaohongshu: '小红书: 西班牙摄影师Lulu',
      email: '邮箱: Luluphotosbcn@gmail.com',
      tiktok: '抖音: 西班牙摄影师Lulu'
    }
  },
  morePhotos: {
    typingText: '我是常驻巴塞罗那摄影师的Lulu，您可以在这里查看我的作品，探索巴塞罗那最佳的旅行拍摄目的地。无论是单人旅拍/情侣拍摄/家庭旅拍/轻婚纱摄影/婚礼跟拍/商业拍摄，我们都能满足您的拍摄需求！',
    navigation: {
      home: '首页',
      gallery: '相册',
      pricing: '价格',
      contact: '预订'
    },
    studioName: 'Lulu Studio'
  },
  footer: {
    copyright: '© 2025 Lulu Studio. 保留所有权利'
  }
};